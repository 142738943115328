<template>
  <v-card rounded="lg" min-height="268">
    <v-card-text>
      <v-chip-group column color="grey lighten-2 white--text" active-class="light-blue">
        <v-chip v-for="tag in tagsCloud" :key="tag._id" small @click="onSelectItem(tag.slug)">
          <v-icon left small>
            {{ getIconName(tag.slug) }}
          </v-icon>
          {{ tag.name }}
        </v-chip>
      </v-chip-group>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    selectedItem: null
  }),
  computed: {
    tagsCloud(){
      let tags = this.$store.getters['tags/cloud'] || [];
      tags.unshift({
        slug: null,
        name: 'All'
      })
      return tags;
    },
  },
  mounted() {
    this.loadTagsCloud();
  },
  methods: {
    /**
     * Load the tags cloud
     * @returns {Promise<boolean>}
     */
    loadTagsCloud() {
      return this.$store.dispatch('tags/cloud');
    },

    /**
     * Create name icon base on name skill
     * @param skill
     * @returns {string}
     */
    getIconName(skill)
    {
      if (skill) {
        let iconName = skill.split('-')[0];
        return `mdi-${iconName} mdi-language-${iconName}`;
      }
    },

    /**
     * On select item
     * @param item
     */
    onSelectItem(item) {
      this.selectedItem = item
      this.$emit('selectItem', item);
    }
  }
}
</script>